<template>
    <transition appear @enter="onEnter">
        <svg
            id="carrot"
            ref="carrotHero"
            xmlns="http://www.w3.org/2000/svg"
            xml:space="preserve"
            :width="size"
            :height="size"
            version="1.1"
            style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
            viewBox="0 0 1012.12 1560.14"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
            class="hero"
        >
            <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <g id="_2586466149920">
                    <g ref="heroBody">
                        <path
                            ref="stalk"
                            class="hero_body stalk"
                            fill="#699895"
                            fill-rule="nonzero"
                            d="M629.31
                        131.73c-3.37,-8.9 -11.71,-17.58 -21.79,-16.89 -17.11,1.18
                        -23.83,17.22 -29.3,31.16 -11.13,28.36 -21.54,57.02 -31.8,85.72
                        1.53,-6.41 3.01,-12.82 4.4,-19.25 5.89,-27.26 12.79,-57.28
                        9.53,-85.32 -0.82,-7.13 -2.71,-17.27 -10.41,-20.14 -6.26,-2.33
                        -12.48,1.35 -16.27,6.21 -6.61,8.46 -9.69,19.85 -12.36,30.05
                        -1.71,6.56 -3.14,13.21 -4.41,19.87 1.43,-20.94 2.74,-41.9
                        3.62,-62.88 0.64,-15.33 1.61,-30.97 0.34,-46.29 -0.49,-5.93
                        -1.98,-10.89 -7.22,-14.5 -6.54,-4.48 -16.02,-5.13 -22.86,-0.89
                        -9.78,6.06 -8.18,21.85 -8.42,31.75 -1,40.15 0.39,80.4
                        1.53,120.52 0.38,13.41 0.84,26.82 1.29,40.23 -1.92,-18.04
                        -3.91,-36.09 -5.77,-54.14 -4.77,-46.23 -10.68,-92.73
                        -12.63,-139.2 -0.43,-10.34 1.05,-22.91 -5.25,-31.82 -5.81,-8.2
                        -16.9,-6.18 -25.25,-3.95 -8.52,2.27 -11.86,7.06 -13.08,15.82
                        -1.59,11.53 0.12,23.6 2,34.99 2.42,14.66 6.76,28.75 9.86,43.22
                        1.91,8.94 3.04,18.12 4.54,27.13 6.39,38.49 12.69,76.99
                        18.59,115.55 2.47,16.08 4.92,32.17 6.93,48.31l0 0c-2.83,-11.81
                        -5.35,-23.68 -7.94,-35.53 -7.43,-33.81 -14.52,-68.04
                        -25.05,-101.06 -3.28,-10.28 -7.23,-26.91 -17.36,-33.04
                        -5.91,-3.57 -13.62,-1.16 -19.54,1.2 -13.69,5.49 -10.52,23.7
                        -8.47,35.21 5.83,32.79 17.46,64.78 27.35,96.48 12.9,41.34
                        24.94,83 43.87,122.1 8.51,17.6 18.17,35.44 31.07,50.29 1.46,1.69
                        2.95,3.41 4.63,4.86 11.24,10.46 28.43,14.37 38.95,1.39
                        8.97,-11.07 15.12,-24.75 21.1,-37.57 19.79,-42.43 35.63,-86.9
                        49.8,-131.5 6.94,-21.84 13.4,-43.86 18.74,-66.15 3.77,-15.7
                        7.52,-31.86 8.26,-48.05 0.21,-4.51 0.41,-9.59 -1.22,-13.89z"
                        />
                        <path
                            class="hero_body"
                            fill="#EE5B3F"
                            fill-rule="nonzero"
                            d="M805.04 687.44c-1.76,-44.07 -6.14,-88.63 -17.76,-131.3 -4.76,-17.48 -10.85,-34.64 -19.05,-50.81 -4.03,-10.9 -11.99,-20.87 -19.68,-29.35 -20.59,-22.66 -48,-38.37 -76.5,-48.88 -46.9,-17.3 -98.01,-20.96 -147.58,-18.45 -35.18,1.77 -70.44,7.09 -103.83,18.57 -31,10.66 -60.09,26.83 -84.42,48.91 -26.4,23.96 -46.3,54.15 -60.15,86.87 -42.02,99.3 -33.81,212.64 -22.89,317.18 12.76,122.13 31.11,246.07 70.54,362.78 14.76,43.71 32.79,87.26 58.83,125.58 19.02,27.98 43.22,53.62 74.55,67.62 34.97,15.63 75.53,12.69 110.8,-0.67 43.79,-16.58 79.18,-49.76 106.49,-87.04 30.15,-41.17 51.96,-88.29 67.82,-136.64 12.3,-37.51 19.73,-75.93 26.43,-114.74 14.76,-85.38 26.7,-171.38 32.98,-257.84 3.66,-50.47 5.44,-101.19 3.42,-151.79z"
                        />
                    </g>
                    <g ref="eyes">
                        <path
                            ref="eyeLeftWhite"
                            class=" eye eye_left white"
                            fill="white"
                            fill-rule="nonzero"
                            d="M515.47 732.81c-2.55,36.35 -27.9,64.2 -56.63,62.19 -28.74,-2 -49.98,-33.11 -47.44,-69.46 2.54,-36.36 27.89,-64.21 56.62,-62.21 28.75,2.01 49.98,33.11 47.45,69.48z"
                        />
                        <path
                            ref="eyeLeft"
                            class="eye eye_left"
                            fill="#283039"
                            fill-rule="nonzero"
                            d="M501.65 749.94c0,21.12 -17.11,38.23 -38.22,38.23 -21.11,0 -38.22,-17.11 -38.22,-38.23 0,-21.1 17.11,-38.21 38.22,-38.21 21.11,0 38.22,17.11 38.22,38.21z"
                        />
                        <path
                            ref="eyeLeftBrow"
                            class="eye eye_left eyebrow"
                            fill="#283039"
                            fill-rule="nonzero"
                            d="M480.63 614.02c-8.66,-5.95 -22.82,-2.4 -32.2,0.61 -10.87,3.51 -20.8,9.26 -28.53,17.69 -4.96,5.39 1.57,15.2 8.2,10.63 8.19,-5.62 16.57,-10.49 26.32,-12.83 8.84,-2.13 17.93,0.03 26.21,-3.63 5.37,-2.38 3.98,-9.74 0,-12.47z"
                        />
                        <path
                            ref="eyeRightBrow"
                            class=" eye eye_right eyebrow"
                            fill="#283039"
                            fill-rule="nonzero"
                            d="M602.42 634.75c-6.78,-1.91 -11.32,-8.18 -17.6,-11.45 -6.93,-3.62 -14.6,-5.26 -22.36,-5.6 -6.67,-0.3 -8.89,-10.88 -1.66,-12.24 9.9,-1.88 19.65,-1.17 29.07,2.57 7.92,3.15 19.33,9.02 21.16,18.12 1.02,5.07 -3.18,10.12 -8.61,8.6z"
                        />
                        <path
                            ref="eyeRightWhite"
                            class="eye eye_right white"
                            fill="white"
                            fill-rule="nonzero"
                            d="M633.97 732.81c-2.54,36.35 -27.89,64.2 -56.62,62.19 -28.74,-2 -49.98,-33.11 -47.45,-69.46 2.55,-36.36 27.9,-64.21 56.63,-62.21 28.74,2.01 49.97,33.11 47.44,69.48z"
                        />
                        <path
                            ref="eyeRight"
                            class="eye eye_right"
                            fill="#283039"
                            fill-rule="nonzero"
                            d="M620.16 749.94c0,21.12 -17.11,38.23 -38.22,38.23 -21.11,0 -38.22,-17.11 -38.22,-38.23 0,-21.1 17.11,-38.21 38.22,-38.21 21.11,0 38.22,17.11 38.22,38.21z"
                        />
                    </g>
                    <path
                        ref="mouth"
                        class="mouth"
                        fill="#282F39"
                        fill-rule="nonzero"
                        d="M594.09 1034.8c-18.29,9.67 -35.61,13.68 -56.16,14.18 -18.67,0.46 -30.52,-8.27 -46.93,-14.94 -8.96,-3.65 -13.75,10.21 -6.31,15.03 21.11,13.7 34.74,18.72 60.34,17.11 21.11,-1.33 41.7,-5.82 58.46,-19.18 7.14,-5.7 -1.96,-16.15 -9.4,-12.2z"
                    />
                    <path
                        ref="footRight"
                        class="foot_right"
                        fill="#283039"
                        fill-rule="nonzero"
                        d="M832.28 1446.98c-45.42,1.34 -103.26,8.23 -147.05,31.25 -6.02,-0.03 -11.71,0.26 -16.87,0.95 1.49,-24.94 -0.8,-51.25 -0.61,-74.39 0.16,-20.54 6.74,-57.7 -8.03,-74.21 -5.06,-5.65 -16.19,-4.52 -19.56,2.52 -0.17,0.36 -0.35,0.73 -0.52,1.09 -1.85,3.88 -1.74,7.92 0.04,11.34 7.9,25.58 2.47,61.5 2.77,87.64 0.22,18.91 1.41,81.32 5.33,83.08 0.39,1.24 203.42,22.58 222.8,24.25 27.16,2.36 89.18,2.3 89.88,-37.5 0.95,-54.65 -94.13,-57.03 -128.18,-56.02z"
                    />
                    <path
                        ref="footLeft"
                        class="foot_left"
                        fill="#283039"
                        fill-rule="nonzero"
                        d="M409.65 1335.78c-18.13,50.3 -22.98,106.43 -19.09,160.02 -54.24,-19.9 -114.82,-21.67 -171.51,-12.99 -22.37,3.42 -98.53,11.12 -93.07,51.16 4.14,30.33 48.41,26.45 69.02,25.47 66.13,-3.12 134.35,-12.33 197.85,-31.03 2.99,-0.89 19.16,-7.28 19.72,-15.23 4.15,-58.53 0.93,-114.46 21.99,-170.54 5.83,-15.52 -19.29,-22.47 -24.91,-6.86z"
                    />
                    <path
                        ref="armLeft"
                        class="arm_left"
                        :class="{ say_hi: animationIs === 'sayHi' }"
                        fill="#282F39"
                        fill-rule="nonzero"
                        d="M325.1 1128.21c-78.9,-13.47 -143.5,-44.2 -204.86,-85.13 -13.54,-7.53 -15.38,-13.9 -15.38,-13.9 -2.6,-28.05 -9.03,-58.6 -26.38,-79.41 -3.51,-4.22 -11.92,-0.93 -11.31,4.68 1.59,14.43 6.85,27.72 10.27,41.76 1.58,6.47 2.78,12.97 3.83,19.49 -16.07,-12 -32.09,-24.6 -48.25,-37.72 -7.59,-6.17 -16.82,3.09 -10.69,10.69 14.96,18.53 32.12,35.33 50.73,50.68 -21.48,3.87 -42.87,2.29 -66.49,0.22 -6.76,-0.58 -9,9.47 -3.39,12.56 26.64,14.62 59.15,13.57 88.2,4.77 1.88,0.83 3.91,1.3 5.94,1.1 65.78,46.65 146.13,76.63 221.66,92.41 14.58,3.05 20.58,-19.74 6.12,-22.2z"
                    />
                    <path
                        ref="armRight"
                        class="arm_right"
                        fill="#282F39"
                        fill-rule="nonzero"
                        d="M864.27 1080.61c-43.64,-20.39 -103.4,-16.51 -138.32,18.53 -6.41,6.43 2.89,17.56 10.6,13.89 44.51,-21.25 89.33,-36.89 134.68,-5.88 19.52,13.34 35.64,33.3 44.06,55.46 7.14,18.82 3.19,39.9 10.53,57.85 0.3,0.73 0.82,1.36 1.34,1.98 -14.44,19.76 -25.66,52.48 -14.72,74.46 3.01,6.05 11.45,4.98 12.77,-1.7 2.34,-11.75 0.72,-23.62 4.03,-35.43 2.35,-8.31 6.03,-15.67 10.49,-22.73 2.62,6.31 5.62,12.52 7.79,18.39 4.69,12.65 7.9,26.58 15.22,37.97 2.78,4.36 11.07,2.4 11.14,-2.99 0.19,-13.4 -4.82,-26.8 -8.92,-39.44 -2.18,-6.65 -4.2,-14.44 -6.92,-21.83 5.19,2.34 10.03,5.4 15.05,9.89 10.24,9.13 17.66,20.6 27.12,30.42 5.27,5.48 13.79,0.95 11.54,-6.57 -7.31,-24.36 -43.13,-64.63 -72.18,-54.88 -5.64,-26.43 -2.2,-49.42 -18,-75.57 -13.54,-22.42 -33.55,-40.73 -57.3,-51.82z"
                    />
                </g>
            </g>
        </svg>
    </transition>
</template>

<script>
import gsap from "gsap";
export default {
    name: "Carrot",

    props: {
        size: {
            type: Number,
            required: false,
            default: 90,
        },

        animationIs: {
            type: String,
            required: false,
            default: null,
        },
    },

    methods: {
        onEnter() {
            if (!this.animationIs) return;
            if (this.animationIs === "squeeze") {
                this.squeeze();
                return;
            } else if (this.animationIs === "jump") {
                this.jump();
                return;
            } else if (this.animationIs === "sad") {
                this.sad();
                return;
            }
        },

        squeeze() {
            var tl = gsap.timeline({ repeat: -1, repeatDelay: 1 });
            gsap.set(this.$refs.carrotHero, { transformStyle: "preserve-3d" });
            tl.to(this.$refs.eyes, {
                y: -120,
                x: -130,
                scale: 2,
                duration: 0.5,
                ease: "bounce.out",
            })
                .to(this.$refs.eyeRightBrow, { duration: 1 }, 0)
                .to(this.$refs.carrotHero, { rotationY: 30, duration: 0.1 }, 0)
                .to(
                    this.$refs.mouth,
                    { y: -10, x: 0, scaleX: 1.2, scaleY: 3, duration: 0.1 },
                    0
                )
                .to(
                    this.$refs.mouth,
                    { y: 0, x: 0, scaleX: 1, scaleY: 1, duration: 0.3 },
                    0.7
                )
                .to(
                    this.$refs.eyes,
                    { y: 0, x: 0, scale: 1, duration: 0.3 },
                    0.7
                )
                .to(
                    this.$refs.carrotHero,
                    { rotationY: 0, skewX: 0, duration: 0.8 },
                    0.7
                );
        },

        jump() {
            var tl = gsap.timeline({ repeat: -1, repeatDelay: 4 });
            gsap.set(this.$refs.carrotHero, { x: 80, y: 0 });
            tl.to(
                this.$refs.carrotHero,
                {
                    x: -40,
                    y: -60,
                    rotate: "-340deg",
                    duration: 0.7,
                },
                0
            )
                .to(
                    this.$refs.carrotHero,
                    {
                        x: -50,
                        y: 0,
                        rotate: "-360deg",
                        duration: 0.5,
                    },
                    0.4
                )
                .to(
                    this.$refs.carrotHero,
                    {
                        x: -50,
                        y: 0,
                        rotate: "0deg",
                        duration: 0,
                    },
                    0.9
                )

                .to(
                    this.$refs.carrotHero,
                    {
                        x: 70,
                        y: -40,
                        rotate: "340deg",
                        duration: 0.5,
                    },
                    1.5
                )
                .to(
                    this.$refs.carrotHero,
                    {
                        x: 80,
                        y: 0,
                        rotate: "360deg",
                        duration: 0.5,
                    },
                    1.8
                );
        },

        sad() {
            var tl = gsap.timeline({ repeat: -1 });
            gsap.set("#carrot .hero", { transformStyle: "preserve-3d" });

            gsap.set(this.$refs.mouth, {
                rotate: "180deg",
                translateX: 120,
                translateY: 30,
            });

            gsap.set(this.$refs.armLeft, {
                rotate: "300deg",
                translateY: 300,
                translateX: -30,
            });
        },
    },
};
</script>

<style scoped>
* {
    overflow: visible;
}
.say_hi {
    transform-origin: bottom right;
    transform: rotate(10deg) translateX(-300px) translateY(200px);
    animation: hi infinite 4s ease-in-out;
}

@keyframes hi {
    0% {
        transform: rotate(10deg) translateX(-150px) translateY(-100px);
    }

    5% {
        transform: rotate(40deg) translateX(-300px) translateY(400px);
    }

    10% {
        transform: rotate(10deg) translateX(-150px) translateY(-100px);
    }

    90% {
        transform: rotate(10deg) translateX(-150px) translateY(-100px);
    }

    95% {
        transform: rotate(40deg) translateX(-300px) translateY(400px);
    }

    100% {
        transform: rotate(10deg) translateX(-150px) translateY(-100px);
    }
}
</style>
